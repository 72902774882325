import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class Header extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			characterShow  : false,
			itemShow       : false,
			keywordShow    : false,
			cognitoid      : '',
		}
		this._getData     = this._getData.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleModalChange = this.handleModalChange.bind(this);//追加 200117
	}
//-----------追加 200117-----------
//モーダル切り替え
		handleModalChange(event){
			if(event.target.checked!==true){
				this.setState({
					characterShow  : false,
					itemShow       : false,
					keywordShow    : false
				});
			}else{
				if(event.target.name==='characterShow'){
					this.setState({
						characterShow  : true,
						itemShow       : false,
						keywordShow    : false
					});
				}else if(event.target.name==='itemShow'){
					this.setState({
						characterShow  : false,
						itemShow       : true,
						keywordShow    : false
					});
				}else if(event.target.name==='keywordShow'){
					this.setState({
						characterShow  : false,
						itemShow       : false,
						keywordShow    : true
					});
				}
			}
		}
//-----------追加 200117-----------

	async _getData() {
		//ログインの確認
		try{
			//Cognito IDの取得
			const user         = await API.Auth.currentAuthenticatedUser();
			const cognitoid    = user.username;

			//ログイン済みの場合フラグ
			if(cognitoid){
				this.setState({
					cognitoid  : cognitoid,
				 });
			}
		}
		catch(error) {
			//
		}
	}
	async handleSubmit(event){
		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;

			//Formから直接取得する項目
			const keyword       = formObj.keyword.value;

			//モーダル削除
			this.setState({
				keywordShow : false,
			});

			//検索画面へ
			//this.props.history.push('/search/' + keyword + '/');
			window.location.href = '/search/' + keyword + '/';


			//if(this.props.reload){
			//	this.props.reload(keyword);
			//}
		}
		catch(error) {
			//
		}
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		//-----------追加 200122-----------
		//新商品更新日 ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■
		var newitem_update='10月11日';
		//「新商品1」カテゴリーの月  ■ ■ ■ ■ ■
		var newitem_month=10;


		//【設定不要】「新商品2」カテゴリーの月
		var newitem_lastmonth;
		if(newitem_month===1){	newitem_lastmonth=12;	}
		else{		newitem_lastmonth=newitem_month-1;		}
		//-----------追加 200122-----------


		return (
			<header class="fixed-top">
				<Container>
					<div class="head_nav">
						<ul class="flexbox-start">
						<li className={this.state.cognitoid ? 'mailmagazine members' : 'mailmagazine'}><a href="/member/"><i className="fas fa-envelope-open-text"></i><br /> 会員登録 </a></li>
							<li><a href="/guide/"><i className="fas fa-book"></i><br /> GUIDE </a></li>
							<li><a href={this.state.cognitoid ? '/logout/' : '/login/'}><i className="fas fa-lock"></i><br /> {this.state.cognitoid ? 'LOGOUT' : 'LOGIN'}< /a></li>
							{/*<li><a href="/mypage/wishlist/"><i className="fas fa-heart"></i><br /> WISH LIST </a></li>*/}
							<li><a href="/mypage/"><i className="far fa-smile"></i><br /> MY PAGE </a></li>
							<li><a href="/cart/"><i className="fas fa-shopping-cart"></i><br /> CART <br /></a></li>
	<li className="smp_search"><input type="checkbox" id="togglenav_smp" /><label htmlFor="togglenav_smp" class=""><i className="fas fa-search"></i><br /> SEARCH </label>

    <input type="checkbox" id="togglenav_smp"/>
    <label htmlFor="togglenav_smp" class="search-nav togglenav-btn">キーワードで探す<p>KEYWORD</p></label>
    <div class="drawer04">
<ul class="drawer-menu">
				<li><a href="/category/今週発売/"><i className="fas fa-caret-right"></i> 新商品（{newitem_update}UP）</a></li>
					<li><a href="/category/新商品1/"><i className="fas fa-caret-right"></i> {newitem_month}月発売商品</a></li>
						<li><a href="/category/新商品2/"><i className="fas fa-caret-right"></i> {newitem_lastmonth}月発売商品</a></li>
</ul>

					<h3>キーワードで探す</h3>
<Form onSubmit={e => this.handleSubmit(e)}>
		<Form.Control type="text" name="keyword" size="lg" placeholder="" required pattern=".{1,255}" />
		<Button size="sm" type="submit" className="btn-blue mt-3 btn-block" >
			検索
		</Button>
</Form>
<div className="ac-menu">
    <input id="ac-001s" type="checkbox" />
    <label for="ac-001s"><h4 className="catettl">たべもの</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-たべもの/"><i className="fas fa-caret-right"></i> たべもの すべて</a></li>
		<li><a href="/category/アイテムから探す-たべもの-スイーツ/"><i className="fas fa-caret-right"></i> スイーツ</a></li>
		<li><a href="/category/アイテムから探す-たべもの-フルーツ/"><i className="fas fa-caret-right"></i> フルーツ</a></li>
		<li><a href="/category/アイテムから探す-たべもの-のみもの/"><i className="fas fa-caret-right"></i> のみもの</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-002s" type="checkbox" />
    <label for="ac-002s"><h4 className="catettl">どうぶつ</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-どうぶつ/"><i className="fas fa-caret-right"></i> どうぶつ すべて</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-犬/"><i className="fas fa-caret-right"></i> 犬</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-猫/"><i className="fas fa-caret-right"></i> 猫</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-うさぎ/"><i className="fas fa-caret-right"></i> うさぎ</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-くま/"><i className="fas fa-caret-right"></i> くま</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-パンダ/"><i className="fas fa-caret-right"></i> パンダ</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-鳥/"><i className="fas fa-caret-right"></i> 鳥</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-海のどうぶつ/"><i className="fas fa-caret-right"></i> 海のどうぶつ</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-003s" type="checkbox" />
    <label for="ac-003s"><h4 className="catettl">おすすめキーワード</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-花・植物/"><i className="fas fa-caret-right"></i> 花・植物</a></li>
		<li><a href="/category/アイテムから探す-のりもの/"><i className="fas fa-caret-right"></i> のりもの</a></li>
		<li><a href="/category/アイテムから探す-雑貨-ぬいぐるみコレクション/"><i className="fas fa-caret-right"></i> ぬいぐるみコレクション</a></li>
		<li><a href="/search/テスト対策グッズ/"><i className="fas fa-caret-right"></i> テスト対策グッズ</a></li>
		<li><a href="/category/ギフトセット/"><i className="fas fa-caret-right"></i> ギフトセット</a></li>
		<li><a href="/category/アイテムから探す-限定商品/"><i className="fas fa-caret-right"></i> 限定商品</a></li>
		<li><a href="/others/30th/"><i className="fas fa-caret-right"></i> 30th記念キャラクター総選挙</a></li>
		<li><a href="/category/アイテムから探す-コラボ商品/"><i className="fas fa-caret-right"></i> 話題のコラボ商品</a></li>
		<li><a href="/category/アイテムから探す-特集-衛生関連用品/"><i className="fas fa-caret-right"></i> 衛生関連アイテム特集</a></li>
	</ul>
    </div></div>
<h3>色で探す</h3>
<div className="srch_color">
	<ul>
		<li><a href="/category/色から探す-赤/" className="srch_rd">赤</a></li>
		<li><a href="/category/色から探す-ピンク/" className="srch_pk">ピンク</a></li>
		<li><a href="/category/色から探す-紫/" className="srch_pu">紫</a></li>
		<li><a href="/category/色から探す-青/" className="srch_bl">青</a></li>
		<li><a href="/category/色から探す-緑/" className="srch_gr">緑</a></li>
		<li><a href="/category/色から探す-黄色/" className="srch_ye">黄色</a></li>
		<li><a href="/category/色から探す-オレンジ/" className="srch_or">オレンジ</a></li>
		<li><a href="/category/色から探す-セピア/" className="srch_sp">セピア</a></li>
		<li><a href="/category/色から探す-ブラウン/" className="srch_br">ブラウン</a></li>
		<li><a href="/category/色から探す-モノクロ/" className="srch_bk">モノクロ</a></li>
		<li><a href="/category/色から探す-白/" className="srch_wh">白</a></li>
		<li><a href="/category/色から探す-カラフル/" className="srch_cf">カラフル</a></li>
	</ul>
</div>

	<h3>キャラクターから探す</h3>
<ul class="drawer-menu cate-character-drawer">
	<li><a href="/category/キャラクターから探す-しばんばん/"><Image src="/img/cate-icon/cara01.jpg" /><p>しばんばん</p></a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ/"><Image src="/img/cate-icon/cara02.jpg" /><p>ごろごろにゃんすけ</p></a></li>
			<li><a href="/category/キャラクターから探す-ごろにゃん＆しばんばん%E3%80%80コラボ/"><Image src="/img/cate-icon/cara11.jpg" /><p>ごろごろにゃんすけ<br />＆しばんばん</p></a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆/"><Image src="/img/cate-icon/cara05.jpg" /><p>ぴよこ豆</p></a></li>
			<li><a href="/category/キャラクターから探す-うさぎのムーちゃん/"><Image src="/img/cate-icon/cara12.jpg" /><p>うさぎのムーちゃん</p></a></li>
			<li><a href="/category/キャラクターから探す-ツンダちゃん/"><Image src="/img/cate-icon/cara13.jpg" /><p>ツンダちゃん</p></a></li>
			<li><a href="/category/キャラクターから探す-ばいばいべあ/"><Image src="/img/cate-icon/cara14.jpg" /><p>ばいばいべあ</p></a></li>
			<li><a href="/category/キャラクターから探す-ようこそかわうそ/"><Image src="/img/cate-icon/cara15.jpg" /><p>ようこそかわうそ</p></a></li>
			<li><a href="/category/キャラクターから探す-ゆるあにまる/"><Image src="/img/cate-icon/cara16.jpg" /><p>ゆるあにまる</p></a></li>
			<li><a href="/category/キャラクターから探す-KIGURU ME/"><Image src="/img/cate-icon/cara03.jpg" /><p>KIGURU ME</p></a></li>
			<li><a href="/category/キャラクターから探す-だっとちゃん/"><i className="fas fa-caret-right"></i> だっとちゃん</a></li>
			<li><a href="/category/キャラクターから探す-にゃばんばん/"><i className="fas fa-caret-right"></i> にゃばんばん</a></li>
			<li><a href="/category/キャラクターから探す-おすもうさんズ/"><i className="fas fa-caret-right"></i> おすもうさんズ</a></li>
			<li><a href="/category/キャラクターから探す-くまのがっこう/"><i className="fas fa-caret-right"></i> くまのがっこう</a></li>
			<li><a href="/category/キャラクターから探す-すてきなおじさん/"><i className="fas fa-caret-right"></i> すてきなおじさん</a></li>
			<li><a href="/category/キャラクターから探す-すみっコぐらし/"><i className="fas fa-caret-right"></i> すみっコぐらし</a></li>
			<li><a href="/search/白熊さんのお仕事/"><i className="fas fa-caret-right"></i> 白熊さんのお仕事</a></li>
			<li><a href="/category/キャラクターから探す-LINE配信キャラクター/"><i className="fas fa-caret-right"></i> LINE配信キャラクター</a></li>
			</ul>
			<h3>シリーズから探す</h3>
			<ul class="drawer-menu cate-character-drawer">
			<li><a href="/category/キャラクターから探す-シアー フォト シリーズ/"><i className="fas fa-caret-right"></i> シアー フォト</a></li>
			<li><a href="/category/キャラクターから探す-ことりまち商店街/"><i className="fas fa-caret-right"></i> ことりまち商店街</a></li>
			<li><a href="/category/キャラクターから探す-BABYシリーズ/"><i className="fas fa-caret-right"></i> BABYシリーズ</a></li>
			<li><a href="/category/キャラクターから探す-おしりシリーズ"><i className="fas fa-caret-right"></i> おしりシリーズ</a></li>
			<li><a href="/search/delish time/"><i className="fas fa-caret-right"></i> delish time</a></li>
			<li><a href="/search/JUICE STAND/"><i className="fas fa-caret-right"></i> JUICE STAND</a></li>
			<li><a href="/search/サロン ド フルール/"><i className="fas fa-caret-right"></i> サロン ド フルール</a></li>
			<li><a href="/category/キャラクターから探す-La Dolce Vita/"><i className="fas fa-caret-right"></i> La Dolce Vita</a></li>
			<li><a href="/category/キャラクターから探す-make me most"><i className="fas fa-caret-right"></i> make me most</a></li>
			<li><a href="/category/キャラクターから探す-un ru"><i className="fas fa-caret-right"></i> un ru</a></li>
			</ul>
				<h3>アイテムで探す</h3>
				<div className="ac-menu">
    <input id="ac-1s" type="checkbox" />
    <label for="ac-1s"><h4 className="catettl">シール</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-シール/"><i className="fas fa-caret-right"></i> シールすべて</a></li>
		<li><a href="/category/アイテムから探す-シール-売れ筋シール/"><i className="fas fa-caret-right"></i> 売れ筋シール</a></li>
		<li><a href="/category/アイテムから探す-シール-シアー%20フォト%20シリーズ/"><i className="fas fa-caret-right"></i> シアーフォト</a></li>
		<li><a href="/category/アイテムから探す-シール-プチプチシール/"><i className="fas fa-caret-right"></i> プチプチシール</a></li>
		<li><a href="/category/アイテムから探す-シール-他フレークシール/"><i className="fas fa-caret-right"></i> フレークシール</a></li>
		<li><a href="/category/アイテムから探す-シール-ハート･スターシール/"><i className="fas fa-caret-right"></i> ハート・スターシール</a></li>
		<li><a href="/category/アイテムから探す-シール-コパンコピーヌシール/"><i className="fas fa-caret-right"></i> コパンコピーヌシール</a></li>
		<li><a href="/category/アイテムから探す-シール-フラワーシール/"><i className="fas fa-caret-right"></i> フラワーシール</a></li>
		<li><a href="/category/アイテムから探す-シール-スケジュールシール/"><i className="fas fa-caret-right"></i> スケジュールシール</a></li>
		<li><a href="/search/ONE%20POINT%20SEAL/"><i className="fas fa-caret-right"></i> ワンポイントシール</a></li>
		<li><a href="/category/アイテムから探す-シール-かきこめるシール/"><i className="fas fa-caret-right"></i> かきこめるシール</a></li>
		<li><a href="/category/アイテムから探す-シール-よくできましたシール/"><i className="fas fa-caret-right"></i> よくできましたシール</a></li>
		<li><a href="/category/アイテムから探す-シール-150ステッカー/"><i className="fas fa-caret-right"></i> 150ステッカー</a></li>
		<li><a href="/category/アイテムから探す-シール-文字シール/"><i className="fas fa-caret-right"></i> 文字シール</a></li>
		<li><a href="/category/アイテムから探す-シール-季節シール/"><i className="fas fa-caret-right"></i> 季節シール</a></li>		
		<li><a href="/category/アイテムから探す-シール-MWステッカー/"><i className="fas fa-caret-right"></i> MWステッカー</a></li>
		<li><a href="/category/アイテムから探す-シール-マスキングシール/"><i className="fas fa-caret-right"></i> マスキングシール</a></li>
		<li><a href="/category/アイテムから探す-シール-トレーシングペーパーシール/"><i className="fas fa-caret-right"></i> トレーシングペーパーシール</a></li>
		<li><a href="/category/アイテムから探す-シール-マシュマロシール/"><i className="fas fa-caret-right"></i> マシュマロシール</a></li>
		<li><a href="/category/アイテムから探す-シール-ミラーコートシール/"><i className="fas fa-caret-right"></i> ミラーコートシール</a></li>
		<li><a href="/category/アイテムから探す-シール-パール紙シール/"><i className="fas fa-caret-right"></i> パール紙シール</a></li>
		<li><a href="/category/アイテムから探す-シール-グリッターシール/"><i className="fas fa-caret-right"></i> グリッターシール</a></li>
		<li><a href="/category/アイテムから探す-シール-ドロップシール/"><i className="fas fa-caret-right"></i> ドロップシール</a></li>
		<li><a href="/category/アイテムから探す-シール-箔押しシール/"><i className="fas fa-caret-right"></i> 箔押しシール</a></li>
		<li><a href="/category/アイテムから探す-シール-和紙シール/"><i className="fas fa-caret-right"></i> 和紙シール</a></li>
		<li><a href="/category/アイテムから探す-シール-上質紙シール/"><i className="fas fa-caret-right"></i> 上質紙シール</a></li>
		<li><a href="/category/アイテムから探す-シール-透明PETシール/"><i className="fas fa-caret-right"></i> 透明PETシール</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-2s" type="checkbox" />
    <label for="ac-2s"><h4 className="catettl">テープ</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-テープ/"><i className="fas fa-caret-right"></i> テープすべて</a></li>
		<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ストレート/"><i className="fas fa-caret-right"></i> マスキングテープ ストレート</a></li>
		<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ダイカット/"><i className="fas fa-caret-right"></i> マスキングテープ ダイカット</a></li>
		<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80アソート/"><i className="fas fa-caret-right"></i> マスキングテープ アソート</a></li>
		<li><a href="/search/ぺたロール/"><i className="fas fa-caret-right"></i> ぺたロール</a></li>
		<li><a href="/search/ちぎロール/"><i className="fas fa-caret-right"></i> ちぎロール</a></li>
		<li><a href="/search/クリアテープ/"><i className="fas fa-caret-right"></i> クリアテープ</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-3s" type="checkbox" />
    <label for="ac-3s"><h4 className="catettl">ふせん</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-付箋/"><i className="fas fa-caret-right"></i> ふせん すべて</a></li>
		<li><a href="/category/アイテムから探す-付箋-スタンドスティックマーカー/"><i className="fas fa-caret-right"></i> スタンドスティックマーカー</a></li>
		<li><a href="/category/アイテムから探す-付箋-スティックマーカー/"><i className="fas fa-caret-right"></i> スティックマーカー</a></li>
		<li><a href="/category/アイテムから探す-付箋-ワンポイントマーカー/"><i className="fas fa-caret-right"></i> ワンポイントマーカー</a></li>
		<li><a href="/category/アイテムから探す-付箋-チョイスフセン/"><i className="fas fa-caret-right"></i> チョイスフセン</a></li>
		<li><a href="/category/アイテムから探す-付箋-ToDoリストマーカー/"><i className="fas fa-caret-right"></i> ToDoリストマーカー</a></li>
		<li><a href="/category/アイテムから探す-付箋-オキモチフセン/"><i className="fas fa-caret-right"></i> オキモチフセン</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-4s" type="checkbox" />
    <label for="ac-4s"><h4 className="catettl">レター</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-レター/"><i className="fas fa-caret-right"></i> レターすべて</a></li>
		<li><a href="/category/アイテムから探す-レター-レターセット/"><i className="fas fa-caret-right"></i> レターセット</a></li>
		<li><a href="/category/アイテムから探す-レター-ミニ封筒/"><i className="fas fa-caret-right"></i> ミニ封筒・ポチ袋</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-5s" type="checkbox" />
    <label for="ac-5s"><h4 className="catettl">ノート</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-ノート/"><i className="fas fa-caret-right"></i> ノートすべて</a></li>
		<li><a href="/category/アイテムから探す-ノート-クロスノート/"><i className="fas fa-caret-right"></i> クロスノート</a></li>
		<li><a href="/category/アイテムから探す-ノート-リングノート/"><i className="fas fa-caret-right"></i> リングノート</a></li>
		<li><a href="/category/アイテムから探す-ノート-綴じノート/"><i className="fas fa-caret-right"></i> 綴じノート</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-6s" type="checkbox" />
    <label for="ac-6s"><h4 className="catettl">文具小物</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-文具小物/"><i className="fas fa-caret-right"></i> 文具小物すべて</a></li>
		<li><a href="/category/アイテムから探す-文具小物-クリアファイル/"><i className="fas fa-caret-right"></i> クリアファイル</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ポケットファイル/"><i className="fas fa-caret-right"></i> ポケットファイル</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ゼムクリップ/"><i className="fas fa-caret-right"></i> ゼムクリップ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ダブルクリップ/"><i className="fas fa-caret-right"></i> ダブルクリップ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-プラクリップ/"><i className="fas fa-caret-right"></i> プラクリップ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ペタッとマーカー/"><i className="fas fa-caret-right"></i> ペタッとマーカー</a></li>
		<li><a href="/category/アイテムから探す-文具小物-のり/"><i className="fas fa-caret-right"></i> のり</a></li>
		<li><a href="/category/アイテムから探す-文具小物-テープカッター/"><i className="fas fa-caret-right"></i> テープカッター</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ハサミ/"><i className="fas fa-caret-right"></i> ハサミ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-修正テープ/"><i className="fas fa-caret-right"></i> 修正テープ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-消しゴム/"><i className="fas fa-caret-right"></i> 消しゴム</a></li>
		<li><a href="/category/アイテムから探す-文具小物-鉛筆キャップ/"><i className="fas fa-caret-right"></i> 鉛筆キャップ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-替え芯/"><i className="fas fa-caret-right"></i> 替え芯</a></li>
		<li><a href="/category/アイテムから探す-文具小物-定規/"><i className="fas fa-caret-right"></i> 定規</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-7s" type="checkbox" />
    <label for="ac-7s"><h4 className="catettl">その他文具</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-メモ/"><i className="fas fa-caret-right"></i> メモ</a></li>
		<li><a href="/category/アイテムから探す-スタンプ/"><i className="fas fa-caret-right"></i> スタンプ</a></li>
		<li><a href="/category/アイテムから探す-アルバム/"><i className="fas fa-caret-right"></i> アルバム</a></li>
		<li><a href="/category/アイテムから探す-カード/"><i className="fas fa-caret-right"></i> カード</a></li>
		<li><a href="/category/アイテムから探す-プロフィール帳/"><i className="fas fa-caret-right"></i> プロフィール帳</a></li>
		<li><a href="/category/アイテムから探す-その他紙製品/"><i className="fas fa-caret-right"></i> その他紙製品</a></li>
		<li><a href="/category/アイテムから探す-シール関連商品/"><i className="fas fa-caret-right"></i> シール関連商品</a></li>
		<li><a href="/category/アイテムから探す-シャープ/"><i className="fas fa-caret-right"></i> シャープペン</a></li>
		<li><a href="/category/アイテムから探す-ボールペン/"><i className="fas fa-caret-right"></i> ボールペン</a></li>
		<li><a href="/category/アイテムから探す-ペン/"><i className="fas fa-caret-right"></i> その他ペン</a></li>
		<li><a href="/category/アイテムから探す-鉛筆/"><i className="fas fa-caret-right"></i> 鉛筆</a></li>
		<li><a href="/category/アイテムから探す-ペンケース・ポーチ/"><i className="fas fa-caret-right"></i> ペンケース・ポーチ</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-8s" type="checkbox" />
    <label for="ac-8s"><h4 className="catettl">雑貨</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-雑貨/"><i className="fas fa-caret-right"></i> 雑貨すべて</a></li>
		<li><a href="/category/アイテムから探す-雑貨-ぬいぐるみ/"><i className="fas fa-caret-right"></i> ぬいぐるみ</a></li>
		<li><a href="/category/アイテムから探す-雑貨-マスコット/"><i className="fas fa-caret-right"></i> マスコット</a></li>
		<li><a href="/category/アイテムから探す-雑貨-キーホルダー/"><i className="fas fa-caret-right"></i> キーホルダー</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-9s" type="checkbox" />
    <label for="ac-9s"><h4 className="catettl">その他商品</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-布製品/"><i className="fas fa-caret-right"></i> 布製品</a></li>
		<li><a href="/category/アイテムから探す-ランチ/"><i className="fas fa-caret-right"></i> ランチ</a></li>
		<li><a href="/category/アイテムから探す-化粧雑貨/"><i className="fas fa-caret-right"></i> 化粧雑貨</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-10s" type="checkbox" />
    <label for="ac-10s"><h4 className="catettl">化粧品</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-化粧品/"><i className="fas fa-caret-right"></i> 化粧品すべて</a></li>
		<li><a href="/category/アイテムから探す-化粧品-フルプルクリーム/"><i className="fas fa-caret-right"></i> フルプルクリーム</a></li>
		<li><a href="/category/アイテムから探す-化粧品-フルプルバスソルト/"><i className="fas fa-caret-right"></i> フルプルバスソルト</a></li>
		<li><a href="/category/アイテムから探す-化粧品-フルプルフェイスマスク/"><i className="fas fa-caret-right"></i> フルプルフェイスマスク</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-11s" type="checkbox" />
    <label for="ac-11s"><h4 className="catettl">モバイルアイテム</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/others/mobileitem/"><i className="fas fa-caret-right"></i> モバイルアイテムすべて</a></li>
		<li><a href="/others/glasscase/"><i className="fas fa-caret-right"></i> 背面ガラスケース</a></li>
		{/*<li><a href="/others/android/"><i className="fas fa-caret-right"></i> クリアケース</a></li>*/}
		<li><a href="/others/sandcase/"><i className="fas fa-caret-right"></i> サンドケース</a></li>
		<li><a href="/others/mobilebattery/"><i className="fas fa-caret-right"></i> モバイルバッテリー・充電器</a></li>
		<li><a href="/others/smp/"><i className="fas fa-caret-right"></i> その他モバイルアイテム</a></li>
	</ul>
    </div></div>
		{/*<ul class="drawer-menu cate-character-drawer">
					<h4 className="catettl"><a href="/category/アイテムから探す-シール/">シール <span class="float-right"><i className="fas fa-arrow-right"></i></span></a></h4>
						<ul className="cate-item-drawer pb-3">
							<li><a href="/category/アイテムから探す-シール-マスキングシール/"><i className="fas fa-caret-right"></i> マスキングシール</a></li>
							<li><a href="/category/アイテムから探す-シール-MWシール/"><i className="fas fa-caret-right"></i> MWシール</a></li>
							<li><a href="/category/アイテムから探す-シール-プチプチシール/"><i className="fas fa-caret-right"></i> プチプチシール</a></li>
							<li><a href="/category/アイテムから探す-シール-スケジュールシール/"><i className="fas fa-caret-right"></i> スケジュールシール</a></li>
							<li><a href="/search/ONE%20POINT%20SEAL/"><i className="fas fa-caret-right"></i> ワンポイントシール</a></li>
							<li><a href="/category/アイテムから探す-シール-よくできましたシール/"><i className="fas fa-caret-right"></i> よくできましたシール</a></li>
							<li><a href="/category/アイテムから探す-シール-コパンコピーヌシール/"><i className="fas fa-caret-right"></i> コパンコピーヌシール</a></li>
							<li><a href="/search/ウィークエンド%20ステッカー/"><i className="fas fa-caret-right"></i> ウィークエンドステッカー</a></li>
							<li><a href="/category/アイテムから探す-シール-ハートシール/"><i className="fas fa-caret-right"></i> ハートシール</a></li>
							<li><a href="/category/アイテムから探す-シール-スターシール/"><i className="fas fa-caret-right"></i> スターシール</a></li>
							<li><a href="/category/アイテムから探す-シール-フラワーシール/"><i className="fas fa-caret-right"></i> フラワーシール</a></li>
							<li><a href="/category/アイテムから探す-シール-季節シール/"><i className="fas fa-caret-right"></i> 季節シール</a></li>
							<li><a href="/category/アイテムから探す-シール-他フレークシール/"><i className="fas fa-caret-right"></i> フレークシール</a></li>
							<li><a href="/category/アイテムから探す-シール-MWステッカー/"><i className="fas fa-caret-right"></i> MWステッカー</a></li>
							
					</ul>
					<h4 className="catettl"><a href="/category/アイテムから探す-テープ/">マスキングテープ <span class="float-right"><i className="fas fa-arrow-right"></i></span></a></h4>
					<ul class="drawer-menu cate-character-drawer">
					<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ストレート/"><i className="fas fa-caret-right"></i> ストレート</a></li>
					<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ダイカット/"><i className="fas fa-caret-right"></i> ダイカット</a></li>
					<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80アソート/"><i className="fas fa-caret-right"></i> アソート</a></li>
					<li><a href="/search/ぺたロール/"><i className="fas fa-caret-right"></i> ぺたロール</a></li>
					<li><a href="/search/ちぎロール/"><i className="fas fa-caret-right"></i> ちぎロール</a></li>
					</ul>
					<h4 className="catettl">その他商品</h4>
					<ul className="cate-item-drawer pb-3">
							<li><a href="/category/アイテムから探す-スタンプ/"><i className="fas fa-caret-right"></i> スタンプ</a></li>
							<li><a href="/category/アイテムから探す-アルバム/"><i className="fas fa-caret-right"></i> アルバム</a></li>
							<li><a href="/category/アイテムから探す-付箋/"><i className="fas fa-caret-right"></i> 付箋</a></li>
							<li><a href="/category/アイテムから探す-レター/"><i className="fas fa-caret-right"></i> レター用品</a></li>
							<li><a href="/category/アイテムから探す-文具小物-クリアファイル/"><i className="fas fa-caret-right"></i> クリアファイル</a></li>
							<li><a href="/category/アイテムから探す-メモ/"><i className="fas fa-caret-right"></i> メモ</a></li>
							<li><a href="/category/アイテムから探す-ノート/"><i className="fas fa-caret-right"></i> ノート</a></li>
							<li><a href="/category/アイテムから探す-文具小物/"><i className="fas fa-caret-right"></i> 文具小物</a></li>
							<li><a href="/category/アイテムから探す-布製品/"><i className="fas fa-caret-right"></i> 布製品</a></li>
							<li><a href="/category/アイテムから探す-ランチ/"><i className="fas fa-caret-right"></i> ランチ用品</a></li>
							<li><a href="/category/アイテムから探す-雑貨-ぬいぐるみ/"><i className="fas fa-caret-right"></i> ぬいぐるみ</a></li>
							<li><a href="/category/アイテムから探す-雑貨/"><i className="fas fa-caret-right"></i> 雑貨</a></li>
							<li><a href="/category/アイテムから探す-化粧品/"><i className="fas fa-caret-right"></i> 化粧品</a></li>
							<li><a href="/category/アイテムから探す-限定商品(モバイル)-iPhone/"><i className="fas fa-caret-right"></i> iPhoneケース</a></li>
							<li><a href="/category/アイテムから探す-限定商品(モバイル)-Android/"><i className="fas fa-caret-right"></i> Androidケース</a></li>
							<li><a href="/category/アイテムから探す-手帳型スマホケース/"><i className="fas fa-caret-right"></i> マルチ手帳型ケース</a></li>
							<li><a href="/category/アイテムから探す-限定商品(モバイル)-スマホリング/"><i className="fas fa-caret-right"></i> スマホリング</a></li>
							<li><a href="/search/コロラージュテープ/"><i className="fas fa-caret-right"></i> コロラージュテープ</a></li>
							<li><a href="/category/アイテムから探す-ダイアリー/"><i className="fas fa-caret-right"></i> ダイアリー</a></li>
							<li><a href="/category/アイテムから探す-鉛筆/"><i className="fas fa-caret-right"></i> 鉛筆</a></li>
							<li><a href="/category/アイテムから探す-文具小物-ペタッとマーカー/"><i className="fas fa-caret-right"></i> ペタッとマーカー</a></li>
							<li><a href="/category/アイテムから探す-文具小物-ポケットファイル/"><i className="fas fa-caret-right"></i> ポケットファイル</a></li>
							<li><a href="/category/アイテムから探す-文具小物-のり/"><i className="fas fa-caret-right"></i> のり</a></li>
							<li><a href="/category/アイテムから探す-文具小物-ハサミ/"><i className="fas fa-caret-right"></i> ハサミ</a></li>
							<li><a href="/category/アイテムから探す-布製品-ハンカチ・タオル/"><i className="fas fa-caret-right"></i> ハンカチ・タオル</a></li>
							<li><a href="/category/アイテムから探す-雑貨-キーホルダー/"><i className="fas fa-caret-right"></i> キーホルダー</a></li>
							<li><a href="/category/アイテムから探す-雑貨-マグカップ/"><i className="fas fa-caret-right"></i> マグカップ</a></li>
							<li><a href="/category/アイテムから探す-雑貨-マスコット/"><i className="fas fa-caret-right"></i> マスコット</a></li>
							
						</ul>
		</ul>*/}
    </div>
	</li>
	</ul>
					</div>
					<div>
						<div class="head-logo hp"><a href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a></div>

    <input type="checkbox" id="togglenav_chara" onChange={this.handleModalChange} checked={this.state.characterShow} name="characterShow"/>
    <label htmlFor="togglenav_chara" class="search-nav togglenav-btn">キャラクターで探す<p>CHARACTER</p></label>
    <div class="drawer01">
		<h3>キャラクターから探す</h3>
		<ul class="drawer-menu cate-character-drawer">
			<li><a href="/category/キャラクターから探す-しばんばん/"><Image src="/img/cate-icon/cara01.jpg" /><p>しばんばん</p></a></li>
			<li><a href="/category/キャラクターから探す-ごろごろにゃんすけ/"><Image src="/img/cate-icon/cara02.jpg" /><p>ごろごろにゃんすけ</p></a></li>
			<li><a href="/category/キャラクターから探す-ごろにゃん＆しばんばん%E3%80%80コラボ/"><Image src="/img/cate-icon/cara11.jpg" /><p>ごろごろにゃんすけ<br />＆しばんばん</p></a></li>
			<li><a href="/category/キャラクターから探す-ぴよこ豆/"><Image src="/img/cate-icon/cara05.jpg" /><p>ぴよこ豆</p></a></li>
			<li><a href="/category/キャラクターから探す-うさぎのムーちゃん/"><Image src="/img/cate-icon/cara12.jpg" /><p>うさぎのムーちゃん</p></a></li>
			<li><a href="/category/キャラクターから探す-ツンダちゃん/"><Image src="/img/cate-icon/cara13.jpg" /><p>ツンダちゃん</p></a></li>
			<li><a href="/category/キャラクターから探す-ばいばいべあ/"><Image src="/img/cate-icon/cara14.jpg" /><p>ばいばいべあ</p></a></li>
			<li><a href="/category/キャラクターから探す-ようこそかわうそ/"><Image src="/img/cate-icon/cara15.jpg" /><p>ようこそかわうそ</p></a></li>
			<li><a href="/category/キャラクターから探す-ゆるあにまる/"><Image src="/img/cate-icon/cara16.jpg" /><p>ゆるあにまる</p></a></li>
			<li><a href="/category/キャラクターから探す-KIGURU ME/"><Image src="/img/cate-icon/cara03.jpg" /><p>KIGURU ME</p></a></li>
			<li><a href="/category/キャラクターから探す-だっとちゃん/"><i className="fas fa-caret-right"></i> だっとちゃん</a></li>
			<li><a href="/category/キャラクターから探す-にゃばんばん/"><i className="fas fa-caret-right"></i> にゃばんばん</a></li>
			<li><a href="/category/キャラクターから探す-おすもうさんズ/"><i className="fas fa-caret-right"></i> おすもうさんズ</a></li>
			<li><a href="/category/キャラクターから探す-くまのがっこう/"><i className="fas fa-caret-right"></i> くまのがっこう</a></li>
			<li><a href="/category/キャラクターから探す-すてきなおじさん/"><i className="fas fa-caret-right"></i> すてきなおじさん</a></li>
			<li><a href="/category/キャラクターから探す-すみっコぐらし/"><i className="fas fa-caret-right"></i> すみっコぐらし</a></li>
			<li><a href="/search/白熊さんのお仕事/"><i className="fas fa-caret-right"></i> 白熊さんのお仕事</a></li>
			<li><a href="/category/キャラクターから探す-LINE配信キャラクター/"><i className="fas fa-caret-right"></i> LINE配信キャラクター</a></li>
		</ul>
	<h3>シリーズから探す</h3>
		<ul class="drawer-menu cate-character-drawer">
			<li><a href="/category/キャラクターから探す-シアー フォト シリーズ/"><i className="fas fa-caret-right"></i> シアー フォト</a></li>
			<li><a href="/category/キャラクターから探す-ことりまち商店街/"><i className="fas fa-caret-right"></i> ことりまち商店街</a></li>
			<li><a href="/category/キャラクターから探す-BABYシリーズ/"><i className="fas fa-caret-right"></i> BABYシリーズ</a></li>
			<li><a href="/category/キャラクターから探す-おしりシリーズ"><i className="fas fa-caret-right"></i> おしりシリーズ</a></li>
			<li><a href="/search/delish time/"><i className="fas fa-caret-right"></i> delish time</a></li>
			<li><a href="/search/JUICE STAND/"><i className="fas fa-caret-right"></i> JUICE STAND</a></li>
			<li><a href="/search/サロン ド フルール/"><i className="fas fa-caret-right"></i> サロン ド フルール</a></li>
			<li><a href="/category/キャラクターから探す-La Dolce Vita/"><i className="fas fa-caret-right"></i> La Dolce Vita</a></li>
			<li><a href="/category/キャラクターから探す-make me most"><i className="fas fa-caret-right"></i> make me most</a></li>
			<li><a href="/category/キャラクターから探す-un ru"><i className="fas fa-caret-right"></i> un ru</a></li>
			{/*
											<li><a href="/search/プラススタディー/"><i className="fas fa-caret-right"></i> プラススタディー</a></li>
											<li><a href="/search/にっぽん%20いっぱい/"><i className="fas fa-caret-right"></i> にっぽん いっぱい</a></li>
											{/*<li><a href="/search/FOODIES/1/商品名順（昇順）/"><i className="fas fa-caret-right"></i> FOODIES</a></li>
											<li><a href="/category/キャラクターから探す-ほわころくらぶ"><i className="fas fa-caret-right"></i> ほわころくらぶ</a></li>
											<li><a href="/category/キャラクターから探す-デリシャススターズ"><i className="fas fa-caret-right"></i> デリシャススターズ</a></li>
											<li><a href="/search/にっぽん%20いっぱい/"><Image src="/img/cate-icon/cara06.jpg" /><p>にっぽん いっぱい</p></a></li>
											<li><a href="/category/キャラクターから探す-くまのがっこう/"><Image src="/img/cate-icon/cara08.jpg" /><p>くまのがっこう</p></a></li>
											<li><a href="/search/おとなのフォト部/"><Image src="/img/cate-icon/cara04.jpg" /><p>おとなのフォト部</p></a></li>
											<li><a href="/category/キャラクターから探す-La Dolce Vita/"><Image src="/img/cate-icon/cara10.jpg" /><p>La Dolce Vita</p></a></li>
											<li><a href="/search/WAKE%20UP/"><Image src="/img/cate-icon/cara07.jpg" /><p>WAKE UP</p></a></li>
											<li><a href="/category/キャラクターから探す-Bear's Cocoa"><i className="fas fa-caret-right"></i> Bear's Cocoa</a></li>
											<li><a href="/category/キャラクターから探す-un ru"><i className="fas fa-caret-right"></i> un ru</a></li>
											<li><a href="/category/キャラクターから探す-すてきなおじさん"><i className="fas fa-caret-right"></i> すてきなおじさん</a></li>
											<li><a href="/category/キャラクターから探す-くまのがっこう"><i className="fas fa-caret-right"></i> くまのがっこう</a></li>
											<li><a href="/search/一期一会/"><i className="fas fa-caret-right"></i> 一期一会</a></li>
											<li><a href="/search/白熊さんのお仕事/"><i className="fas fa-caret-right"></i> 白熊さんのお仕事</a></li>
											<li><a href="/search/音符/"><i className="fas fa-caret-right"></i> 音符</a></li>*/}
      </ul>

    </div>

		    <input type="checkbox" id="togglenav_item"  onChange={this.handleModalChange}  checked={this.state.itemShow} name="itemShow"/>
		    <label htmlFor="togglenav_item" class="search-nav togglenav-btn">アイテムで探す<p>ITEM</p></label>
		    <div class="drawer02">
							<h3>アイテムで探す</h3>
	<div className="ac-menu">
    <input id="ac-1" type="checkbox" />
    <label for="ac-1"><h4 className="catettl">シール</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-シール/"><i className="fas fa-caret-right"></i> シールすべて</a></li>
		<li><a href="/category/アイテムから探す-シール-売れ筋シール/"><i className="fas fa-caret-right"></i> 売れ筋シール</a></li>
		<li><a href="/category/アイテムから探す-シール-シアー%20フォト%20シリーズ/"><i className="fas fa-caret-right"></i> シアーフォト</a></li>
		<li><a href="/category/アイテムから探す-シール-プチプチシール/"><i className="fas fa-caret-right"></i> プチプチシール</a></li>
		<li><a href="/category/アイテムから探す-シール-他フレークシール/"><i className="fas fa-caret-right"></i> フレークシール</a></li>
		<li><a href="/category/アイテムから探す-シール-ハート･スターシール/"><i className="fas fa-caret-right"></i> ハート・スターシール</a></li>
		<li><a href="/category/アイテムから探す-シール-コパンコピーヌシール/"><i className="fas fa-caret-right"></i> コパンコピーヌシール</a></li>
		<li><a href="/category/アイテムから探す-シール-フラワーシール/"><i className="fas fa-caret-right"></i> フラワーシール</a></li>
		<li><a href="/category/アイテムから探す-シール-スケジュールシール/"><i className="fas fa-caret-right"></i> スケジュールシール</a></li>
		<li><a href="/search/ONE%20POINT%20SEAL/"><i className="fas fa-caret-right"></i> ワンポイントシール</a></li>
		<li><a href="/category/アイテムから探す-シール-かきこめるシール/"><i className="fas fa-caret-right"></i> かきこめるシール</a></li>
		<li><a href="/category/アイテムから探す-シール-よくできましたシール/"><i className="fas fa-caret-right"></i> よくできましたシール</a></li>
		<li><a href="/category/アイテムから探す-シール-150ステッカー/"><i className="fas fa-caret-right"></i> 150ステッカー</a></li>
		<li><a href="/category/アイテムから探す-シール-文字シール/"><i className="fas fa-caret-right"></i> 文字シール</a></li>
		<li><a href="/category/アイテムから探す-シール-季節シール/"><i className="fas fa-caret-right"></i> 季節シール</a></li>		
		<li><a href="/category/アイテムから探す-シール-MWステッカー/"><i className="fas fa-caret-right"></i> MWステッカー</a></li>
		<li><a href="/category/アイテムから探す-シール-マスキングシール/"><i className="fas fa-caret-right"></i> マスキングシール</a></li>
		<li><a href="/category/アイテムから探す-シール-トレーシングペーパーシール/"><i className="fas fa-caret-right"></i> トレーシングペーパーシール</a></li>
		<li><a href="/category/アイテムから探す-シール-マシュマロシール/"><i className="fas fa-caret-right"></i> マシュマロシール</a></li>
		<li><a href="/category/アイテムから探す-シール-ミラーコートシール/"><i className="fas fa-caret-right"></i> ミラーコートシール</a></li>
		<li><a href="/category/アイテムから探す-シール-パール紙シール/"><i className="fas fa-caret-right"></i> パール紙シール</a></li>
		<li><a href="/category/アイテムから探す-シール-グリッターシール/"><i className="fas fa-caret-right"></i> グリッターシール</a></li>
		<li><a href="/category/アイテムから探す-シール-ドロップシール/"><i className="fas fa-caret-right"></i> ドロップシール</a></li>
		<li><a href="/category/アイテムから探す-シール-箔押しシール/"><i className="fas fa-caret-right"></i> 箔押しシール</a></li>
		<li><a href="/category/アイテムから探す-シール-和紙シール/"><i className="fas fa-caret-right"></i> 和紙シール</a></li>
		<li><a href="/category/アイテムから探す-シール-上質紙シール/"><i className="fas fa-caret-right"></i> 上質紙シール</a></li>
		<li><a href="/category/アイテムから探す-シール-透明PETシール/"><i className="fas fa-caret-right"></i> 透明PETシール</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-2" type="checkbox" />
    <label for="ac-2"><h4 className="catettl">テープ</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-テープ/"><i className="fas fa-caret-right"></i> テープすべて</a></li>
		<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ストレート/"><i className="fas fa-caret-right"></i> マスキングテープ ストレート</a></li>
		<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ダイカット/"><i className="fas fa-caret-right"></i> マスキングテープ ダイカット</a></li>
		<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80アソート/"><i className="fas fa-caret-right"></i> マスキングテープ アソート</a></li>
		<li><a href="/search/ぺたロール/"><i className="fas fa-caret-right"></i> ぺたロール</a></li>
		<li><a href="/search/ちぎロール/"><i className="fas fa-caret-right"></i> ちぎロール</a></li>
		<li><a href="/search/クリアテープ/"><i className="fas fa-caret-right"></i> クリアテープ</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-3" type="checkbox" />
    <label for="ac-3"><h4 className="catettl">ふせん</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-付箋/"><i className="fas fa-caret-right"></i> ふせん すべて</a></li>
		<li><a href="/category/アイテムから探す-付箋-スタンドスティックマーカー/"><i className="fas fa-caret-right"></i> スタンドスティックマーカー</a></li>
		<li><a href="/category/アイテムから探す-付箋-スティックマーカー/"><i className="fas fa-caret-right"></i> スティックマーカー</a></li>
		<li><a href="/category/アイテムから探す-付箋-ワンポイントマーカー/"><i className="fas fa-caret-right"></i> ワンポイントマーカー</a></li>
		<li><a href="/category/アイテムから探す-付箋-チョイスフセン/"><i className="fas fa-caret-right"></i> チョイスフセン</a></li>
		<li><a href="/category/アイテムから探す-付箋-ToDoリストマーカー/"><i className="fas fa-caret-right"></i> ToDoリストマーカー</a></li>
		<li><a href="/category/アイテムから探す-付箋-オキモチフセン/"><i className="fas fa-caret-right"></i> オキモチフセン</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-4" type="checkbox" />
    <label for="ac-4"><h4 className="catettl">レター</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-レター/"><i className="fas fa-caret-right"></i> レターすべて</a></li>
		<li><a href="/category/アイテムから探す-レター-レターセット/"><i className="fas fa-caret-right"></i> レターセット</a></li>
		<li><a href="/category/アイテムから探す-レター-ミニ封筒/"><i className="fas fa-caret-right"></i> ミニ封筒・ポチ袋</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-5" type="checkbox" />
    <label for="ac-5"><h4 className="catettl">ノート</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-ノート/"><i className="fas fa-caret-right"></i> ノートすべて</a></li>
		<li><a href="/category/アイテムから探す-ノート-クロスノート/"><i className="fas fa-caret-right"></i> クロスノート</a></li>
		<li><a href="/category/アイテムから探す-ノート-リングノート/"><i className="fas fa-caret-right"></i> リングノート</a></li>
		<li><a href="/category/アイテムから探す-ノート-綴じノート/"><i className="fas fa-caret-right"></i> 綴じノート</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-6" type="checkbox" />
    <label for="ac-6"><h4 className="catettl">文具小物</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-文具小物/"><i className="fas fa-caret-right"></i> 文具小物すべて</a></li>
		<li><a href="/category/アイテムから探す-文具小物-クリアファイル/"><i className="fas fa-caret-right"></i> クリアファイル</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ポケットファイル/"><i className="fas fa-caret-right"></i> ポケットファイル</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ゼムクリップ/"><i className="fas fa-caret-right"></i> ゼムクリップ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ダブルクリップ/"><i className="fas fa-caret-right"></i> ダブルクリップ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-プラクリップ/"><i className="fas fa-caret-right"></i> プラクリップ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ペタッとマーカー/"><i className="fas fa-caret-right"></i> ペタッとマーカー</a></li>
		<li><a href="/category/アイテムから探す-文具小物-のり/"><i className="fas fa-caret-right"></i> のり</a></li>
		<li><a href="/category/アイテムから探す-文具小物-テープカッター/"><i className="fas fa-caret-right"></i> テープカッター</a></li>
		<li><a href="/category/アイテムから探す-文具小物-ハサミ/"><i className="fas fa-caret-right"></i> ハサミ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-修正テープ/"><i className="fas fa-caret-right"></i> 修正テープ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-消しゴム/"><i className="fas fa-caret-right"></i> 消しゴム</a></li>
		<li><a href="/category/アイテムから探す-文具小物-鉛筆キャップ/"><i className="fas fa-caret-right"></i> 鉛筆キャップ</a></li>
		<li><a href="/category/アイテムから探す-文具小物-替え芯/"><i className="fas fa-caret-right"></i> 替え芯</a></li>
		<li><a href="/category/アイテムから探す-文具小物-定規/"><i className="fas fa-caret-right"></i> 定規</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-7" type="checkbox" />
    <label for="ac-7"><h4 className="catettl">その他文具</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-メモ/"><i className="fas fa-caret-right"></i> メモ</a></li>
		<li><a href="/category/アイテムから探す-スタンプ/"><i className="fas fa-caret-right"></i> スタンプ</a></li>
		<li><a href="/category/アイテムから探す-アルバム/"><i className="fas fa-caret-right"></i> アルバム</a></li>
		<li><a href="/category/アイテムから探す-カード/"><i className="fas fa-caret-right"></i> カード</a></li>
		<li><a href="/category/アイテムから探す-プロフィール帳/"><i className="fas fa-caret-right"></i> プロフィール帳</a></li>
		<li><a href="/category/アイテムから探す-その他紙製品/"><i className="fas fa-caret-right"></i> その他紙製品</a></li>
		<li><a href="/category/アイテムから探す-シール関連商品/"><i className="fas fa-caret-right"></i> シール関連商品</a></li>
		<li><a href="/category/アイテムから探す-シャープ/"><i className="fas fa-caret-right"></i> シャープペン</a></li>
		<li><a href="/category/アイテムから探す-ボールペン/"><i className="fas fa-caret-right"></i> ボールペン</a></li>
		<li><a href="/category/アイテムから探す-ペン/"><i className="fas fa-caret-right"></i> その他ペン</a></li>
		<li><a href="/category/アイテムから探す-鉛筆/"><i className="fas fa-caret-right"></i> 鉛筆</a></li>
		<li><a href="/category/アイテムから探す-ペンケース・ポーチ/"><i className="fas fa-caret-right"></i> ペンケース・ポーチ</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-8" type="checkbox" />
    <label for="ac-8"><h4 className="catettl">雑貨</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-雑貨/"><i className="fas fa-caret-right"></i> 雑貨すべて</a></li>
		<li><a href="/category/アイテムから探す-雑貨-ぬいぐるみ/"><i className="fas fa-caret-right"></i> ぬいぐるみ</a></li>
		<li><a href="/category/アイテムから探す-雑貨-マスコット/"><i className="fas fa-caret-right"></i> マスコット</a></li>
		<li><a href="/category/アイテムから探す-雑貨-キーホルダー/"><i className="fas fa-caret-right"></i> キーホルダー</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-9" type="checkbox" />
    <label for="ac-9"><h4 className="catettl">その他商品</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-布製品/"><i className="fas fa-caret-right"></i> 布製品</a></li>
		<li><a href="/category/アイテムから探す-ランチ/"><i className="fas fa-caret-right"></i> ランチ</a></li>
		<li><a href="/category/アイテムから探す-化粧雑貨/"><i className="fas fa-caret-right"></i> 化粧雑貨</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-10" type="checkbox" />
    <label for="ac-10"><h4 className="catettl">化粧品</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-化粧品/"><i className="fas fa-caret-right"></i> 化粧品すべて</a></li>
		<li><a href="/category/アイテムから探す-化粧品-フルプルクリーム/"><i className="fas fa-caret-right"></i> フルプルクリーム</a></li>
		<li><a href="/category/アイテムから探す-化粧品-フルプルバスソルト/"><i className="fas fa-caret-right"></i> フルプルバスソルト</a></li>
		<li><a href="/category/アイテムから探す-化粧品-フルプルフェイスマスク/"><i className="fas fa-caret-right"></i> フルプルフェイスマスク</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-11" type="checkbox" />
    <label for="ac-11"><h4 className="catettl">モバイルアイテム</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/others/mobileitem/"><i className="fas fa-caret-right"></i> モバイルアイテムすべて</a></li>
		<li><a href="/others/glasscase/"><i className="fas fa-caret-right"></i> 背面ガラスケース</a></li>
		{/*<li><a href="/others/android/"><i className="fas fa-caret-right"></i> クリアケース</a></li>*/}
		<li><a href="/others/sandcase/"><i className="fas fa-caret-right"></i> サンドケース</a></li>
		<li><a href="/others/mobilebattery/"><i className="fas fa-caret-right"></i> モバイルバッテリー・充電器</a></li>
		<li><a href="/others/smp/"><i className="fas fa-caret-right"></i> その他モバイルアイテム</a></li>
	</ul>
    </div></div>
		    {/*   <ul class="drawer-menu cate-character-drawer">
					<h4 className="catettl"><a href="/category/アイテムから探す-シール/">シール <span class="float-right"><i className="fas fa-arrow-right"></i></span></a></h4>
						<ul className="cate-item-drawer pb-3">
							<li><a href="/category/アイテムから探す-シール-マスキングシール/"><i className="fas fa-caret-right"></i> マスキングシール</a></li>
							<li><a href="/category/アイテムから探す-シール-MWシール/"><i className="fas fa-caret-right"></i> MWシール</a></li>
							<li><a href="/category/アイテムから探す-シール-プチプチシール/"><i className="fas fa-caret-right"></i> プチプチシール</a></li>
							<li><a href="/category/アイテムから探す-シール-スケジュールシール/"><i className="fas fa-caret-right"></i> スケジュールシール</a></li>
							<li><a href="/search/ONE%20POINT%20SEAL/"><i className="fas fa-caret-right"></i> ワンポイントシール</a></li>
							<li><a href="/category/アイテムから探す-シール-よくできましたシール/"><i className="fas fa-caret-right"></i> よくできましたシール</a></li>
							<li><a href="/category/アイテムから探す-シール-コパンコピーヌシール/"><i className="fas fa-caret-right"></i> コパンコピーヌシール</a></li>
							<li><a href="/search/ウィークエンド%20ステッカー/"><i className="fas fa-caret-right"></i> ウィークエンドステッカー</a></li>
							<li><a href="/category/アイテムから探す-シール-ハートシール/"><i className="fas fa-caret-right"></i> ハートシール</a></li>
							<li><a href="/category/アイテムから探す-シール-スターシール/"><i className="fas fa-caret-right"></i> スターシール</a></li>
							<li><a href="/category/アイテムから探す-シール-フラワーシール/"><i className="fas fa-caret-right"></i> フラワーシール</a></li>
							<li><a href="/category/アイテムから探す-シール-季節シール/"><i className="fas fa-caret-right"></i> 季節シール</a></li>
							<li><a href="/category/アイテムから探す-シール-他フレークシール/"><i className="fas fa-caret-right"></i> フレークシール</a></li>
							<li><a href="/category/アイテムから探す-シール-MWステッカー/"><i className="fas fa-caret-right"></i> MWステッカー</a></li>
					</ul>
					<h4 className="catettl"><a href="/category/アイテムから探す-テープ/">マスキングテープ <span class="float-right"><i className="fas fa-arrow-right"></i></span></a></h4>
					<ul class="drawer-menu cate-character-drawer">
					<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ストレート/"><i className="fas fa-caret-right"></i> ストレート</a></li>
					<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80ダイカット/"><i className="fas fa-caret-right"></i> ダイカット</a></li>
					<li><a href="/category/アイテムから探す-テープ-マスキングテープ%E3%80%80アソート/"><i className="fas fa-caret-right"></i> アソート</a></li>
					<li><a href="/search/ぺたロール/"><i className="fas fa-caret-right"></i> ぺたロール</a></li>
					<li><a href="/search/ちぎロール/"><i className="fas fa-caret-right"></i> ちぎロール</a></li>
					</ul>
					<h4 className="catettl">その他商品</h4>
					<ul className="cate-item-drawer pb-3">
							<li><a href="/category/アイテムから探す-スタンプ/"><i className="fas fa-caret-right"></i> スタンプ</a></li>
							<li><a href="/category/アイテムから探す-アルバム/"><i className="fas fa-caret-right"></i> アルバム</a></li>
							<li><a href="/category/アイテムから探す-付箋/"><i className="fas fa-caret-right"></i> 付箋</a></li>
							<li><a href="/category/アイテムから探す-レター/"><i className="fas fa-caret-right"></i> レター用品</a></li>
							<li><a href="/category/アイテムから探す-文具小物-クリアファイル/"><i className="fas fa-caret-right"></i> クリアファイル</a></li>
							<li><a href="/category/アイテムから探す-メモ/"><i className="fas fa-caret-right"></i> メモ</a></li>
							<li><a href="/category/アイテムから探す-ノート/"><i className="fas fa-caret-right"></i> ノート</a></li>
							<li><a href="/category/アイテムから探す-文具小物/"><i className="fas fa-caret-right"></i> 文具小物</a></li>
							<li><a href="/category/アイテムから探す-布製品/"><i className="fas fa-caret-right"></i> 布製品</a></li>
							<li><a href="/category/アイテムから探す-ランチ/"><i className="fas fa-caret-right"></i> ランチ用品</a></li>
							<li><a href="/category/アイテムから探す-雑貨-ぬいぐるみ/"><i className="fas fa-caret-right"></i> ぬいぐるみ</a></li>
							<li><a href="/category/アイテムから探す-雑貨/"><i className="fas fa-caret-right"></i> 雑貨</a></li>
							<li><a href="/category/アイテムから探す-化粧品/"><i className="fas fa-caret-right"></i> 化粧品</a></li>
							<li><a href="/category/アイテムから探す-限定商品(モバイル)-iPhone/"><i className="fas fa-caret-right"></i> iPhoneケース</a></li>
							<li><a href="/category/アイテムから探す-限定商品(モバイル)-Android/"><i className="fas fa-caret-right"></i> Androidケース</a></li>
							<li><a href="/category/アイテムから探す-手帳型スマホケース/"><i className="fas fa-caret-right"></i> マルチ手帳型ケース</a></li>
							<li><a href="/category/アイテムから探す-限定商品(モバイル)-スマホリング/"><i className="fas fa-caret-right"></i> スマホリング</a></li>
						</ul>
		      </ul>*/}
		    </div>


    <input type="checkbox" id="togglenav_keyword"  onChange={this.handleModalChange}  checked={this.state.keywordShow} name="keywordShow"/>
    <label htmlFor="togglenav_keyword" class="search-nav togglenav-btn">キーワードで探す<p>KEYWORD</p></label>
    <div class="drawer03">
			<ul class="drawer-menu">
				<li><a href="/category/今週発売/"><i className="fas fa-caret-right"></i> 新商品（{newitem_update}UP）</a></li>
					<li><a href="/category/新商品1/"><i className="fas fa-caret-right"></i> {newitem_month}月発売商品</a></li>
						<li><a href="/category/新商品2/"><i className="fas fa-caret-right"></i> {newitem_lastmonth}月発売商品</a></li>
			</ul>
				<h3>キーワードで探す</h3>
<Form onSubmit={e => this.handleSubmit(e)}>
		<Form.Control type="text" name="keyword" size="lg" placeholder="" required pattern=".{1,255}" />
		<Button size="sm" type="submit" className="btn-blue mt-3 btn-block" >
			検索
		</Button>
</Form>

<div className="ac-menu">
    <input id="ac-001" type="checkbox" />
    <label for="ac-001"><h4 className="catettl">たべもの</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-たべもの/"><i className="fas fa-caret-right"></i> たべもの すべて</a></li>
		<li><a href="/category/アイテムから探す-たべもの-スイーツ/"><i className="fas fa-caret-right"></i> スイーツ</a></li>
		<li><a href="/category/アイテムから探す-たべもの-フルーツ/"><i className="fas fa-caret-right"></i> フルーツ</a></li>
		<li><a href="/category/アイテムから探す-たべもの-のみもの/"><i className="fas fa-caret-right"></i> のみもの</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-002" type="checkbox" />
    <label for="ac-002"><h4 className="catettl">どうぶつ</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-どうぶつ/"><i className="fas fa-caret-right"></i> どうぶつ すべて</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-犬/"><i className="fas fa-caret-right"></i> 犬</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-猫/"><i className="fas fa-caret-right"></i> 猫</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-うさぎ/"><i className="fas fa-caret-right"></i> うさぎ</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-くま/"><i className="fas fa-caret-right"></i> くま</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-パンダ/"><i className="fas fa-caret-right"></i> パンダ</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-鳥/"><i className="fas fa-caret-right"></i> 鳥</a></li>
		<li><a href="/category/アイテムから探す-どうぶつ-海のどうぶつ/"><i className="fas fa-caret-right"></i> 海のどうぶつ</a></li>
	</ul>
    </div></div>
	<div className="ac-menu">
    <input id="ac-003" type="checkbox" />
    <label for="ac-003"><h4 className="catettl">おすすめキーワード</h4></label>
    <div className="ac-menu-inside">
	<ul className="cate-item-drawer pb-3">
		<li><a href="/category/アイテムから探す-花・植物/"><i className="fas fa-caret-right"></i> 花・植物</a></li>
		<li><a href="/category/アイテムから探す-のりもの/"><i className="fas fa-caret-right"></i> のりもの</a></li>
		<li><a href="/category/アイテムから探す-雑貨-ぬいぐるみコレクション/"><i className="fas fa-caret-right"></i> ぬいぐるみコレクション</a></li>
		<li><a href="/search/テスト対策グッズ/"><i className="fas fa-caret-right"></i> テスト対策グッズ</a></li>
		<li><a href="/category/ギフトセット/"><i className="fas fa-caret-right"></i> ギフトセット</a></li>
		<li><a href="/category/アイテムから探す-限定商品/"><i className="fas fa-caret-right"></i> 限定商品</a></li>
		<li><a href="/others/30th/"><i className="fas fa-caret-right"></i> 30th記念キャラクター総選挙</a></li>
		<li><a href="/category/アイテムから探す-コラボ商品/"><i className="fas fa-caret-right"></i> 話題のコラボ商品</a></li>
		<li><a href="/category/アイテムから探す-特集-衛生関連用品/"><i className="fas fa-caret-right"></i> 衛生関連アイテム特集</a></li>
	</ul>
    </div></div>
	<h3>色で探す</h3>
<div className="srch_color">
	<ul>
	<li><a href="/category/色から探す-赤/" className="srch_rd">赤</a></li>
		<li><a href="/category/色から探す-ピンク/" className="srch_pk">ピンク</a></li>
		<li><a href="/category/色から探す-紫/" className="srch_pu">紫</a></li>
		<li><a href="/category/色から探す-青/" className="srch_bl">青</a></li>
		<li><a href="/category/色から探す-緑/" className="srch_gr">緑</a></li>
		<li><a href="/category/色から探す-黄色/" className="srch_ye">黄色</a></li>
		<li><a href="/category/色から探す-オレンジ/" className="srch_or">オレンジ</a></li>
		<li><a href="/category/色から探す-セピア/" className="srch_sp">セピア</a></li>
		<li><a href="/category/色から探す-ブラウン/" className="srch_br">ブラウン</a></li>
		<li><a href="/category/色から探す-モノクロ/" className="srch_bk">モノクロ</a></li>
		<li><a href="/category/色から探す-白/" className="srch_wh">白</a></li>
		<li><a href="/category/色から探す-カラフル/" className="srch_cf">カラフル</a></li>
	</ul>
</div>
{/*<h4>おすすめキーワード</h4>
	<ul class="drawer-menu">
<li className="search_recommend"><a href="/search/食べ物/"><i className="fas fa-caret-right"></i> 食べ物</a></li>
<li className="search_recommend"><a href="/search/スイーツ/"><i className="fas fa-caret-right"></i> スイーツ</a></li>
<li className="search_recommend"><a href="/search/フルーツ/"><i className="fas fa-caret-right"></i> フルーツ</a></li>
<li className="search_recommend"><a href="/search/のりもの/"><i className="fas fa-caret-right"></i> のりもの</a></li>
<li className="search_recommend"><a href="/category/アイテムから探す-雑貨-ぬいぐるみコレクション/"><i className="fas fa-caret-right"></i> ぬいぐるみコレクション</a></li>
<li className="search_recommend"><a href="/search/テスト対策グッズ/"><i className="fas fa-caret-right"></i> テスト対策グッズ</a></li>
<li className="search_recommend"><a href="/category/ギフトセット/"><i className="fas fa-caret-right"></i> ギフトセット</a></li>
<li className="search_recommend"><a href="/category/アイテムから探す-限定商品/"><i className="fas fa-caret-right"></i> 限定商品</a></li>
<li className="search_recommend w_all"><a href="/others/30th/"><i className="fas fa-caret-right"></i> 30th記念キャラクター総選挙</a></li>
<li className="search_recommend w_all"><a href="/category/アイテムから探す-コラボ商品/"><i className="fas fa-caret-right"></i> 話題のコラボ商品</a></li>
<li className="search_recommend w_all"><a href="/category/アイテムから探す-特集-衛生関連用品/"><i className="fas fa-caret-right"></i> 衛生関連アイテム特集</a></li>
<li className="search_recommend"><a href="/search/かわうそ/"><i className="fas fa-caret-right"></i> かわうそ</a></li>
<li className="search_recommend"><a href="/search/ぺんぎん/"><i className="fas fa-caret-right"></i> ぺんぎん</a></li>
</ul> 
*/}


    </div>

</div>

				</Container>
			</header>
		);
	}
}


export default withRouter(Header);
