import React from 'react';
import { API } from '../@onocomm-cart';
import {
	Container,
	Image,
	Badge,
}from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import SiteFlag from './@Include/SiteFlag';


class StationaryItemSlickNew extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			itemData       : [],
		}
		this._getData         = this._getData.bind(this);
	}

	async _getData() {

try{
			//取得
			//表示させる商品のIDを追加する ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■
			const itemslick =[
				{ id: '61987' },
				{ id: '61988' },
				{ id: '61989' },
				{ id: '82111' },
				{ id: '82112' },
				{ id: '82113' },
				{ id: '82115' },
				{ id: '95455' },
				{ id: '95457' },
				{ id: '95459' }
			];

			//返答
			let ref;
			const items=[];
			for(var i in itemslick){
				ref = await API.Item.Get(SiteFlag.en, itemslick[i].id);
				if(ref.error === null&&ref.item.zaiko!==0){
					items.push({
						code           : itemslick[i].id ,
						name           : ref.item.name        ,
						price          : ref.item.price       ,
						zaiko          : ref.item.zaiko       ,
						zaiko_flag     : ref.item.zaiko_flag  ,
						flag_3         : ref.item.flag_3      ,
					});
				}
				//ref = await API.Item.QueryV2(SiteFlag.en, itemslick[i].id);
				//const items = ref.items ? ref.items : [];
				// if(ref.count !== 0){
			 	// 	items.push(ref.items[0]);
				// }
		 	}
			//console.log(items);

			//データがあれば
			this.setState({
				itemData       : items,
			});
		}

		catch(error) {

		}

	}
	componentDidMount () {
		this._getData();
	}

  render() {
		var settings = {
autoplay:true,
autoplaySpeed:5000,
centerMode: true,
centerPadding: '30px',
swipeToSlide:true,
		dots:false,
		slidesToShow:6,
		responsive:[
			{
				breakpoint: 1800,
				settings:{
					slidesToShow:4,
					swipe: true,
				}
			},
						{
				breakpoint: 1200,
				settings:{
					slidesToShow:3,
					swipe: true,
				}
			},
			{
				breakpoint: 1000,
				settings:{
					slidesToShow:2,
					swipe: true,
				}
			},
			{
				breakpoint: 576,
				settings:{
					slidesToShow:1,
					centerPadding: '0px',
					swipe: true,
				}
			},
		]
    };
    return (

	<Container fluid={true} className="clearfix pb-5 slid-na">
		<div className="multiple-item">
	      <Slider {...settings}>
				{
					this.state.itemData.map( item =>{
						 const code       = item.code;
						 const name       = item.name;
						 const price       = item.price.toLocaleString();
						 const zaiko       = item.zaiko;
						 const flag_3       = item.flag_3;
						 const zaiko_flag  = item.zaiko_flag;
						return(
						<div className="slidsize" key={code}><a href={'/item/' + code }><Image src={'https://images.mindwave-store.com/public/' + code + '_main.jpg'} />
				<h5 className="list-title">{name}</h5>
				<p className="list-price">￥{price}<span>（税込）</span></p>
				{(() => {
					if (flag_3 === true){
																	return(
				<p className="float-left mr-3"><Badge pill variant="info">SALE</Badge></p>
																	);
																}
					})()}
					{(() => {
						if (zaiko === 0){
							return(
								<p className="float-left"><Badge pill variant="warning">在庫０</Badge></p>
								);
							}
					else if (zaiko_flag === false){
						return (
							<p className="float-left"><small>{zaiko} 個</small></p>
									);
				}}
					)()}
					</a></div>
				);
	})}

			</Slider>
	</div>
		</Container>
	   );
  }
}

export default withRouter(StationaryItemSlickNew);
